var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "position--relative pa-0" },
    [
      _c(
        "div",
        {
          class: [
            _vm.$vuetify.breakpoint.smAndUp
              ? "swap-icon-sm-and-up"
              : "swap-icon-xs",
            _vm.isSwap ? "swap-icon" : "",
            "arrow-icon align-center",
          ],
        },
        [
          _c("img", {
            class: [
              _vm.$vuetify.breakpoint.smAndUp ? "" : "xs-svg",
              "pa-1",
              ((_obj = {}),
              (_obj[_vm.$vuetify.breakpoint.smAndUp ? "pl-2" : "pr-2"] =
                !_vm.isSwap),
              _obj),
            ],
            attrs: { src: _vm.swapIcon, width: "30px", contain: "" },
          }),
        ]
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        _vm._l(_vm.items, function (item, index) {
          var _obj
          return _c(
            "v-col",
            {
              key: index,
              class:
                ((_obj = {}),
                (_obj[_vm.$vuetify.breakpoint.smAndUp ? "pr-1" : "pb-1"] =
                  _vm.isSwap && _vm.isEven(index)),
                (_obj[_vm.$vuetify.breakpoint.smAndUp ? "pad-l" : "pad-t"] =
                  !_vm.isSwap && !_vm.isEven(index)),
                (_obj[_vm.$vuetify.breakpoint.smAndUp ? "pad-r" : "pad-b"] =
                  !_vm.isSwap && _vm.isEven(index)),
                _obj),
              attrs: { cols: "12", sm: "6" },
            },
            [
              _vm.isSwap
                ? _c(
                    "div",
                    { staticClass: "value-container text-center py-6" },
                    [
                      _c(
                        "div",
                        { staticClass: "mew-caption textLight--text pb-2" },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-center justify-center" },
                        [
                          _c("mew-token-container", {
                            attrs: { img: item.icon, size: "medium" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mew-heading-4 font-weight-medium textDark--text pt-2",
                        },
                        [
                          _vm._v(" " + _vm._s(item.value) + " "),
                          _c(
                            "span",
                            { staticClass: "mew-body font-weight-medium" },
                            [_vm._v(" " + _vm._s(item.type) + " ")]
                          ),
                        ]
                      ),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "value-container d-flex align-center justify-start pa-6 fill-height",
                    },
                    [
                      item.amount && item.icon
                        ? _c(
                            "div",
                            [
                              _c("mew-token-container", {
                                attrs: { img: item.icon },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !item.amount
                        ? _c(
                            "div",
                            [
                              item.avatar && item.avatar !== ""
                                ? _c("v-img", {
                                    staticClass: "circle",
                                    attrs: {
                                      src: _vm.avatar,
                                      height: "24px",
                                      width: "24px",
                                    },
                                  })
                                : _c("mew-blockie", {
                                    attrs: {
                                      address: item.address,
                                      width: "24px",
                                      height: "24px",
                                    },
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "pl-2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "mew-caption textLight--text font-weight-medium",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(item.amount ? "Sending" : "To Address") +
                                " "
                            ),
                          ]
                        ),
                        item.amount
                          ? _c("div", { staticClass: "pt-2" }, [
                              _c(
                                "div",
                                { staticClass: "mew-heading-2 textDark--text" },
                                [
                                  _vm._v(" " + _vm._s(item.amount) + " "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mew-body font-weight-medium",
                                    },
                                    [_vm._v(" " + _vm._s(item.type) + " ")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "mew-heading-4 textMedium--text",
                                },
                                [_vm._v(_vm._s(item.usd))]
                              ),
                            ])
                          : _c("div", { staticClass: "pt-2" }, [
                              item.nickname !== ""
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mew-heading-3 textDark--text text-truncate",
                                    },
                                    [_vm._v(" " + _vm._s(item.nickname) + " ")]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "addr" },
                                [
                                  item.nickname !== ""
                                    ? _c("mew-transform-hash", {
                                        staticClass:
                                          "textMedium--text mew-body",
                                        attrs: { hash: item.address },
                                      })
                                    : _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-wrap textMedium--text mew-body mb-0",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getXDCAddress(item.address)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              ),
                              item.ensName !== ""
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "greenPrimary--text mew-body",
                                    },
                                    [_vm._v(" " + _vm._s(item.ensName) + " ")]
                                  )
                                : _vm._e(),
                            ]),
                      ]),
                    ]
                  ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }